@import '~assets/styles/variables.sass'

.update-course-page
  max-width: 50%
  &__title,
  &__field
    margin-bottom: 15px!important
  &__flex
    display: flex
    align-items: center
    justify-content: space-between
    margin: 20px 0
  &__switch
    .MuiSwitch-track,.MuiSwitch-thumb
      background-color: #1976d2!important
  &__switch-text
    font: 1.4rem $r
    cursor: pointer
