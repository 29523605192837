@import '~assets/styles/variables.sass'
.modal-template
  max-width: 620px
  margin: 30px auto
  display: inline-block
  vertical-align: middle
  width: 100%
  position: relative
  background: $white
  border-radius: 4px
  overflow: hidden
  padding: 20px
  background: $white
  text-align: left
  &__close
    position: absolute
    right: 15px
    top: 15px
    width: 35px!important
    height: 35px!important
    cursor: pointer
  &__title
    margin-bottom: 35px