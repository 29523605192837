@import ~assets/styles/variables

.student-contracts-page
  &__top
    display: flex
    align-items: center
    grid-gap: 20px
    margin-bottom: 35px
    svg
      width: 35px
      height: 35px
      cursor: pointer
  form
    margin-bottom: 35px
  &__table-cell
    color: $red!important
    &--active
      color: $green!important
