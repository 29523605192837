@font-face
  font-family: 'Inter-Regular'
  src: url('../fonts/Inter-Regular.woff') format('woff'), url('../fonts/Inter-Regular.woff2') format('woff2'), url('../fonts/Inter-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Inter-Medium'
  src: url('../fonts/Inter-Medium.woff') format('woff'), url('../fonts/Inter-Medium.woff2') format('woff2'), url('../fonts/Inter-Medium.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Inter-Bold'
  src: url('../fonts/Inter-Bold.woff') format('woff'), url('../fonts/Inter-Bold.woff2') format('woff2'), url('../fonts/Inter-Bold.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Tahoma'
  src: url('../fonts/Tahoma.woff') format('woff'), url('../fonts/Tahoma.woff2') format('woff2'), url('../fonts/Tahoma.ttf') format('truetype')
  font-weight: normal
  font-style: normal