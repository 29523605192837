@import '~assets/styles/variables.sass'

.update-student-page
  max-width: 675px
  &__title,
  &__field
    margin-bottom: 15px!important
  &__flex
    display: flex
    grid-gap: 10px
    margin: 20px 0
  &__switch-text
    font: 1.4rem $r
    cursor: pointer
  &__contract
    margin-bottom: 15px!important
