@import ~assets/styles/variables
.lessons
  width: 100%
  &__top
    display: flex
    justify-content: space-between
    align-items: center
  &__tabs
    display: flex
    align-items: center
    grid-gap: 10px
    margin-top: 20px
  &__tabs-item
    padding: 10px
    display: flex
    align-items: center
    grid-gap: 5px
    border: 1px solid #CDD5E0
    border-radius: 6px
    transition: all 0.3s ease
    cursor: pointer
    font: 1.4rem $m
    &:hover, &--active
      background: #CDD5E0
  &__hide-button
    width: fit-content!important
    padding: 0 15px 0!important
    svg
      transition: all 0.3s ease
    &--hidden
      svg
        transform: rotate(180deg)
  &__title
    flex: 1
    font: 1.8rem $b
  &__container
    margin-top: 20px
    transition: all 0.3s ease
    border-top: 1px solid #EAEAEA
    border-bottom: 1px solid #EAEAEA
    padding: 12px 0
    width: 100%
    &--hide
      margin-top: 0
      overflow: hidden
      height: 0
    &--without-list
      display: none
  &__list
    display: flex
    grid-gap: 10px
    flex-wrap: wrap
    flex: 1
  &__item
    list-style: none
    font: 1.4rem $b
    border-radius: 6px
    border: 1px solid #CDD5E0
    transition: all 0.3s ease
    cursor: pointer
    padding: 10px
    display: flex
    grid-gap: 5px
    align-items: center
    position: relative
    background: #F4F4F4
    &:hover,
    &--active
      background: #CDD5E0
    &-type-icon
      &--theory
        fill: #dd9611!important
      &--run
        fill: $orange!important
    &-contract-status
      font: 0.8rem $m
      color: green
      position: absolute
      right: 2px
      top: 0
      display: none
      width: 10px!important
      height: 10px!important
      svg
        width: 100%
      &--payed
        display: block
        color: green
      &--new
        display: block
        color: red
  &__show-button
    border: none
    margin-left: auto
    padding: 0
    outline: none
    border: none
    background: $white
    padding: 10px
    font: 1.2rem $m
    line-height: 24px
    width: 100%
    padding: 4px
    margin-top: 12px
    background: #F4F4F4
    border-radius: 6px
    &:hover
      background: #CDD5E0
