@import ~assets/styles/variables
.notification
  position: fixed
  right: 35px
  top: 100px
  width: fit-content
  color: $white
  padding: 10px 25px
  border-radius: 5px
  font: 1.7rem $r
  z-index: 99
  &--warning
    background: $red
  &--success
    background: $green
  &--info
    background: $yellow
  &__message
    display: flex
    align-items: center
    grid-gap: 5px
  svg
    width: 30px
    height: 30px
    margin-right: 5px
