

  .update-lesson-page
    &__row
      margin-bottom: 15px
      display: flex
      justify-content: space-between
    &__paper
      padding: 10px
      margin-bottom: 15px
    tr
      cursor: auto!important
      &:hover
        background: none!important
    td
      min-height: 62px!important
      text-align: center
      &:first-child
        text-align: left
        &:hover
          background: none!important
          cursor: auto
      &:nth-child(2)
        &:hover
          background: none!important
          cursor: auto
      &:hover
        cursor: pointer
        background: rgba(0, 0, 0, .03)!important
    th
      text-align: center
      &:first-child
        text-align: left
    &__table
      min-width: 350px
      th,
      td
        text-align: left
        border-bottom: 0!important