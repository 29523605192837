@import '~assets/styles/variables.sass'

.update-tariff-page
  max-width: 675px
  &__title
    margin-bottom: 15px
  &__field
    margin-bottom: 15px!important
  &__add
    max-width: 99px
    display: inline-block
  &__delete
    margin-top: 4px!important
  &__add-wrap
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid rgba(0, 0, 0, 0.4)
    margin-bottom: 25px
    padding-bottom: 15px
  &__add-label
    font: 2rem $m
  .css-y6rp3m-MuiButton-startIcon
    margin: 0!important