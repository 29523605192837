@import '~assets/styles/variables.sass'
.update-contract-page
  max-width: 100%
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 24px
    cursor: pointer
  &__settings
    width: 45px!important
    height: 45px!important
    path
      fill: $light-blue
  &__window
    position: absolute
    right: 0
    top: calc(100%)
    width: 220px
    background: $white
    box-shadow: 0px 16px 24px rgba(24, 25, 26, 0.1)
    border-radius: 4px
    opacity: 0
    visibility: hidden
    padding-top: 10px
  &__item
    font: 1.5rem $m
    cursor: pointer
    padding: 12px 12px 6px 12px
    &:last-child
      margin-bottom: 6px
  &__top
    display: flex
    justify-content: space-between
    margin-bottom: 15px
    align-items: center
    button
      max-width: 150px
  &__field
    margin-bottom: 15px
  table
    width: 100%
    margin-bottom: 50px
    position: relative
    &::before
      content: ''
      left: -30px
      right: -30px
      bottom: -20px
      border: 1px solid #E6E8EC
      width: calc(100% + 60px)
      position: absolute
  td
    &:nth-child(1)
      font: 1.4rem/2.4rem $m
    &:nth-child(2)
      font: 1.4rem/2.4rem $r
  &__subtitle
    font: 3rem/3.6rem $b
  &__label
    font: 2rem $m
    margin-bottom: 16px
  &__inner
    border-radius: 10px
    background: #F5F6FA
    padding: 30px
  &__in
    background: $white
    padding: 30px
    border-radius: 10px
    margin-bottom: 35px
    box-shadow: 0px 16px 24px rgba(24, 25, 26, 0.1)
  &__actions
    border-radius: 10px
    padding: 15px
    background: $white
  &__buttons
    margin-top: 10px!important
  &__title
    flex: 1
  &__contract
    margin: 15px 0
  &__methods
    display: flex
    justify-content: space-between
  &__pay
    width: calc(33.33% - 6px)
    background: rgba(224, 224, 224, 1)
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    height: 34px
    color: $white
    font: 1.3rem $m
    cursor: pointer
    &--active
      background: $light-blue
  &__wrap
    display: flex
    align-items: center
    position: relative
    &:hover
      .update-contract-page__window
        opacity: 1
        visibility: visible
  &__statuses-item
    display: flex
    align-items: center
    padding: 26px
    border-radius: 16px
    &--active
      border: 2px solid #F5F6FA
      .update-contract-page__statuses-number
        background: green
        color: $white
    &--completed
      .update-contract-page__statuses-number
        background: $blue
        color: $white
    &--disabled
      .update-contract-page__statuses-number
        background: $red
        color: $white
  &__statuses-number
    font: 2.4rem/3.2rem $m
    color: $blue
    background: #F5F6FA
    border-radius: 50%
    width: 54px
    height: 54px
    display: flex
    align-items: center
    justify-content: center
    margin-right: 24px
  &__statuses-text
    font: 2.2rem $m
    color: $blue
  &__modal
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 10
    &-overlay
      position: absolute
      left: 0
      top: 0
      background: rgba(0, 0, 0, 0.7)
      height: 100%
      width: 100%
      height: 100%
      z-index: 11
    &-in
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
    &-container
      width: 620px
      padding: 20px
      position: relative
      z-index: 12
      border-radius: 10px!important
    &-close-icon
      position: absolute
      top: 10px
      right: 10px!important
      width: 20px!important
      height: 20px!important
      cursor: pointer
    &-edit-title
      margin-bottom: 25px
      font-size: 2.4rem!important

@media print
  .header
    display: none!important
  .middle
    display: none!important
  .print-modal
    display: block
@page
  size: A4 landscape
  margin: 0