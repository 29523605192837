@import '~assets/styles/variables.sass'

.update-group-page
  max-width: 675px
  &__title
    margin-bottom: 15px
  &__field
    margin-bottom: 15px!important
    &--flex
      display: flex
      align-items: center
  &__item
    display: flex
    align-items: center
    margin-bottom: 15px
  &__name
    flex: 1
    border: 1px solid rgba(0, 0, 0, .5)
    border-radius: 4px
    padding: 5px 14px
    font: 1.4rem $r
    min-height: 46px
    display: flex
    align-items: center
  &__add
    width: 50px!important
    margin-left: 15px!important
    font-size: 2.4rem!important
  &__delete
    width: 50px!important
    margin-left: 15px!important
    font-size: 2.4rem!important
  &__contracts
    margin-bottom: 15px
  &__hr
    border-bottom: 1px solid rgba(0, 0, 0, .5)
    margin-bottom: 25px
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 15px
  &__label
    font: 2rem $m
  .MuiTableRow-root:hover
    background: none!important
    cursor: auto!important
  .MuiButton-startIcon
    margin: 0!important
  th
    border-bottom: 1px solid rgba(0, 0, 0, .4)
  td
    border-bottom: 1px solid rgba(0, 0, 0, .4)
  .MuiPaper-rounded
    border: 1px solid rgba(0, 0, 0, .4)
  .MuiIconButton-sizeMedium
    &:not(:first-child)
      margin-left: 10px
  &__flex
    display: flex
    align-items: center