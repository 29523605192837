@import './normalize.scss'
@import './fonts.sass'
@import './variables.sass'
@import './material.sass'

html, body
  height: 100%
  font-size: 10px
  @media (max-width: $xxl)
    font-size: 8px

body
  margin: 0
  -webkit-print-color-adjust: exact !important

*
  box-sizing: border-box
  outline: none

#root
  min-height: 100%
  position: relative
  overflow: hidden
  display: flex
  flex-direction: column
  width: 100%

.middle
  flex: 1
  display: flex
  &__container
    display: flex
    flex: 1
    align-items: flex-start
    padding-top: 35px
    padding-bottom: 35px
  &__content
    padding-left: 35px
    position: relative
    width: calc(100% - 305px)
    &--fluid
      width: calc(100% - 105px)
  &__button
    position: absolute
    right: 0
    top: 0
    border-radius: 40px
    width: 50px
    height: 50px
    cursor: pointer
    svg
      width: 100%
      height: 100%
      path
        fill: $blue

.container
  max-width: 1510px
  margin: 0 auto
  padding-left: 35px
  padding-right: 35px
  @media (max-width: $xl)
    max-width: 1270px

a
  text-decoration: none

ul
  padding: 0
  margin: 0

img
  max-width: 100%
  height: auto

.row
  display: flex
  align-items: center
  button:nth-child(2)
    margin-left: 5px

.delete-button
  background: $red!important

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.react-datepicker-popper
  z-index: 10!important
  padding-top: 12px!important
.react-datepicker-time__input
  font: 1.2rem $m
.react-datepicker-time__caption
  font: 1.2rem $m
.react-datepicker
  &__triangle
    transform: translate3d(205px, 0, 0)!important
  &__header
    padding-top: 0.8em
  &____month
    margin: 0.4em 1em
  &__day-name
    width: 2rem!important
    font: 1.2rem $r
  &__day
    width: 2rem!important
    font: 0.9rem $r
    &--selected
      background: $blue
  &__current-month
    font: 1.3rem $m!important
    width: 210px
    text-transform: uppercase
    margin-bottom: 5px
  &__navigation
    top: 1em
    line-height: 1.7em
    border: 0.45em solid transparent
    &--previous
      border-right-color: #ccc
      left: 1em
    &--next
      border-left-color: #ccc
      right: 1em

textarea
  font: 1.4rem $r
  color: $black
  height: 150px
  width: 100%
  border: 1px solid rgba(0, 0, 0, 0.5)
  padding: 16.5px 14px
  border-radius: 4px
  resize: none
  &::placeholder
    color: rgba(0, 0, 0, .5)

.go-to
  background: $blue
  min-width: 30px
  min-height: 30px
  max-width: 30px
  max-height: 30px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  svg
    path
      fill: $white
