@import '~assets/styles/variables.sass'

.sidebar
  width: 280px
  background: $blue
  border-radius: 8px
  overflow: hidden
  transition: all 0.3s ease
  &--mini
    width: 70px
  &__link
    padding: 18px 20px
    border-bottom: 2px solid $light-blue
    transition: .3s all ease
    font: 1.7rem $m
    color: $white
    display: flex
    align-items: center
    svg
      margin-right: 8px
      display: inline-block
    &:hover
      background: $light-blue
      svg
        path, circle
          fill: #fff
  &__toggle
    padding: 18px 20px
    border-bottom: 2px solid $light-blue
    transition: .3s all ease
    color: $white
    display: block
    cursor: pointer
    &:hover
      background: $light-blue
      svg
        path
          fill: #fff
