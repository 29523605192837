@import ~assets/styles/variables

.courses-page
  &__title
    margin-bottom: 35px
  form
    margin-bottom: 35px
  &__table-cell
    color: $red!important
    &--active
      color: $green!important
