@import '~assets/styles/variables.sass'


.lesson-modal
  min-height: 350px
  &__row
    display: flex
    align-items: center
    margin-bottom: 10px
  &__label
    margin-bottom: 5px
    font: 1.5rem $m
  &__button
    width: 64px!important
    margin-left: 15px!important
    span
      margin: 0
  .custom-searchable-select__input
    margin-left: 15px