@import ~assets/styles/variables

.confirmation
  .MuiDialog-paper
    padding: 10px
  h2
    font: 2.4rem $b
    color: $black
  p
    font: 2rem $m
  .MuiDialogActions-root
    button
      background: $blue
      color: $white
      font: 1.3rem $b
    button:first-child
      background: gray
