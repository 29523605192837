

.contract-modal
  &__field
    margin-bottom: 15px!important
    &--flex
      display: flex
      .custom-searchable-select
        width: flex 1
      .MuiCheckbox-root
        width: 45px
        svg
          width: 30px
          height: 30px
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    button
      width: calc(50% - 10px)!important