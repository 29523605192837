@import ~assets/styles/variables
.lessons-page
  &__modal
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 10
    &-overlay
      position: absolute
      left: 0
      top: 0
      background: rgba(0, 0, 0, 0.7)
      height: 100%
      width: 100%
      height: 100%
      z-index: 11
    &-in
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    &-top
      display: flex
      align-items: center
      grid-gap: 15px
      padding-bottom: 10px
      margin-bottom: 10px
      padding-right: 15px
    &-info
      display: flex
      grid-gap: 10px
      align-items: center
      flex: 1
      svg
        width: 20px
        height: auto
      &-icon
        position: relative
        height: 20px
        display: flex
        align-items: center
        justify-content: center
        &:hover::after
          content: attr(data-title)
          width: fit-content
          height: 1.2rem
          padding: 2px 3px
          background: $white
          white-space: nowrap
          border-radius: 4px
          position: absolute
          left: 50%
          z-index: 10
          font: 1rem $m
          top: -13px
          border: 1px solid #CDD5E0
    &-edit
      width: fit-content!important
      font: 1rem $r!important
      padding: 5px 7px!important
      height: auto!important
      display: flex!important
      align-items: center!important
      background: rgb(50, 108, 137)!important
      span
        margin-left: 4px!important
      svg
        width: 15px!important
    &-user
      font: 1.6rem $m
      margin-bottom: 20px
      display: flex
      align-items: center
      span
        margin-right: auto
        display: block
        font-family: $b
    &-teachers
      display: flex
      align-items: center
      flex-wrap: wrap
      grid-gap: 10px
    &-teachers-item
      padding: 5px
      border-radius: 6px
      border: 1px solid #CDD5E0
      background: #F4F4F4
      font: 1.2rem $r !important
      cursor: pointer
      transition: all 0.3s ease
      &:hover,
      &--selected
        background: #CDD5E0
    &-fields
      flex: 1
      display: flex
      grid-gap: 20px
      flex-direction: column
    &-container
      width: 620px
      height: fit-content
      min-height: 370px
      overflow: hidden
      padding: 20px
      position: relative
      z-index: 12
      display: flex
      flex-direction: column
    &-close-icon
      position: absolute
      top: 10px
      right: 10px!important
      width: 20px!important
      height: 20px!important
      cursor: pointer
    &-edit-title
      font-size: 2.4rem!important
    .custom-searchable-select
      .custom-searchable-select__list
        max-height: 140px!important
    &-buttons
      flex: 1
      align-items: flex-end!important
      flex-grow: 1!important
  &__title
    font: 1.8rem $b
    margin: 15px 0
