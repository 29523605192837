@import ~assets/styles/variables

.transactions-page
  &__top
    display: flex
    align-items: center
    margin-bottom: 35px
  &__title
    flex: 1
  &__amount
    font: 1.6rem $b
  form
    margin-bottom: 35px
  &__table-cell
    color: $red!important
    &--active
      color: $green!important
  tr
    &:hover
      background: $white!important
      cursor: auto!important
  &__item
    &--selectable
      cursor: pointer
      color: $green!important
      transition: all 0.3s ease
      &:hover
        color: $light-blue!important
