@import ~assets/styles/variables

.not-found-page
  padding: 40px
  background: url('~assets/images/background.png') center center no-repeat, $blue
  height: 100vh
  h1
    color: $white
  button
    border: 1px solid $white
    width: 240px!important
    transition: all 0.3s ease
