@import '~assets/styles/variables.sass'
  
.modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, .6)
  padding: 20px
  z-index: 30
  text-align: center
  overflow-y: auto
  &::before
    content: ""
    display: inline-block
    vertical-align: middle
    height: 100%
    width: 0
  &--white
    background: $white