@import '~assets/styles/variables.sass'
.student-timeline
  tr
    &:hover
      background: none!important
  td
    cursor: auto
  &__title
    margin-bottom: 15px
  &__button
    padding: 5px 12px
    border-radius: 4px
    text-align: center
    color: $white
    display: inline-block
    cursor: pointer
    min-width: 92px
    &--red
      background: $red
    &--green
      background: $green
  &__colspan
    text-align: center!important
  &__paper
    margin-bottom: 15px
  &__yim
    padding: 2px 4px
    border-radius: 4px
    display: inline-block
    cursor: pointer
    &--failed
      background: #FF0000
      color: $white