@import '~assets/styles/variables.sass'
.statuses
  font: 1.3rem/1.7rem $m
  padding: 7px 12px
  border-radius: 6px
  &--new
    color: $black
    background: $yellow
  &--payed
    color: $white
    background: $orange
  &--finished
    color: $white
    background: $green
  &--finished
    color: $white
    background: $red