@import '~assets/styles/variables.sass'

.print-modal
  flex: 1
  position: relative
  display: none
  &--one-time
    .print-modal__in
      right: 4%
      bottom: 24%
      left: inherit
  &__in
    position: absolute
    left: 26%
    bottom: 30.7%
    width: 20%
    height: 18%
    z-index: 1
  &__name
    font: 9px/18px $t
    color: rgba(0,0,0, .8)