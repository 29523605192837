$light-blue: #4acbce
$blue: #003956
$white: #fff
$black: #000
$red: #FF0000
$green: #419D3E
$yellow: #FFE812
$orange: #FB6D3A

$sm: 767px
$md: 991px
$lg: 1199px
$xl: 1440px
$xxl: 1680px

$r: 'Inter-Regular'
$m: 'Inter-Medium'
$b: 'Inter-Bold'
$t: 'Tahoma'

