@import ~assets/styles/variables

.custom-searchable-select
  width: 100%
  font: 1.4rem "GoogleSans-Regular"
  position: relative
  &__icon
    position: absolute
    right: 8px
    top: 50%
    transform: translateY(-50%)
  &__container
    display: flex
    align-items: center
    position: relative
    width: 100%
    color: $black
  &__input
    background: $white
    border: none
    padding: 16px 32px 16px 14px
    flex: 1
    border-radius: 4px
    border: 1px solid rgba(0, 0, 0, 0.5)
    overflow: hidden
    width: 100%
    padding-right: 100px
    display: block
    height: 46px
    color: rgba(0, 0, 0, 0.87)
    font: 1.4rem "Inter-Regular"
    &--error
      border-color: red
      color: red
    &--white
      background: $white
    &--disabled
      color: hsl(0, 0%, 60%)
  &__list-container
    width: 100%
    max-height: 220px
    overflow-y: auto
  &__list
    height: fit-content
    width: 100%
    border-radius: 4px
    opacity: 0
    right: 0
    overflow: hidden
    visibility: hidden
    border: 1.5px solid #b7b7b7
    top: calc(100% + 5px)
    max-height: 220px
    position: absolute
    z-index: 9999
    background: $white
    &--active
      opacity: 1
      visibility: visible
    &-item
      padding: 8px
      background: $white
      cursor: pointer
      transition: all 0.3s ease
      &:hover
        background: rgba(0, 0, 0, .5)
      &:not(:last-child)
        border-bottom: 1px solid $white
      &--empty
        text-align: center
        color: green
    &--white
      .custom-searchable-select__list-item
        background: $white
      .custom-searchable-select__list-item:hover
        background: $white
  &__loading
    display: inline-block
    position: relative
    width: 56px
    height: 36px
    & span
      position: absolute
      top: 0
      bottom: 0
      margin: auto 0
      width: 5px
      height: 5px
      border-radius: 50%
      background: #fff
      background-color: black
      animation-timing-function: cubic-bezier(0, 1, 1, 0)
    & span:nth-child(1)
      left: 4px
      animation: loading 0.6s infinite

    & span:nth-child(2)
      left: 4px
      animation: loading2 0.6s infinite

    & span:nth-child(3)
      left: 16px
      animation: loading2 0.6s infinite

    & span:nth-child(4)
      left: 29px
      animation: loading3 0.6s infinite

@keyframes loading
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes loading3
  0%
    transform: scale(1)

  100%
    transform: scale(0)

@keyframes loading2
  0%
    transform: translate(0, 0)

  100%
    transform: translate(12px, 0)
