@import '~assets/styles/variables.sass'

.auth-template
  display: flex
  align-items: center
  justify-content: center
  flex: 1
  padding: 35px
  background: url('~assets/images/background.png') center center no-repeat, $blue
  background-size: cover
  @media (max-width: $sm)
    padding: 15px
  &__window
    max-width: 540px
    border-radius: 24px
    box-shadow: 0px 16px 24px rgba(24, 25, 26, 0.1)
    padding: 42px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    background: $white
    @media (max-width: $xxl)
      max-width: 480px
    @media (max-width: $sm)
      border-radius: 12px
      padding: 15px
  &__icon
    margin-bottom: 24px
    @media (max-width: $xxl)
      max-width: 110px
    @media (max-width: $sm)
      max-width: 90px
      margin-bottom: 10px
  &__title
    text-align: center
    font: 3.6rem/4.2rem $b
    color: #18191A
    margin-bottom: 32px