@import '~assets/styles/variables.sass'

.header
  padding: 12px 0
  background: $blue
  &__logo
    max-width: 110px
    display: block
    min-width: 110px
  &__container
    display: flex
    align-items: center
    justify-content: space-between
  &__logout
    cursor: pointer