@import ~assets/styles/variables
.calendar
  width: 100%
  overflow-x: auto
  &__item
    padding: 6px
    cursor: pointer
    display: flex
    flex-direction: column
    height: 100%
  &__item-top
    display: flex
    align-items: center
    grid-gap: 5px
    font: 1.2rem $r
    svg, path
      fill: $white
  &__item-title
    margin-left: auto
  &__item-bottom
    display: flex
    grid-gap: 5px
    align-items: center
    margin-top: auto
    svg
      width: 10px
      height: 10px
    b
      font: 1.2rem $m
  &__item--not-active
    position: relative
    &::after
      content: ""
      position: absolute
      left: 0
      top: 0
      bottom: 0
      height: 2px
      background: $red
      width: 100%
      margin: auto 0
  .fc-daygrid-body,.fc-scrollgrid-sync-table,.fc-col-header
    width: 100%!important
  .fc tr td
    padding: 0px!important
  .fc .fc-timegrid-slot
    height: 4.5rem!important
  .fc-button-group
    grid-gap: 5px
  .fc-direction-ltr .fc-timegrid-col-events
    margin-right: 1%!important
    margin-left: 1%!important
  .fc-button
    border: none!important
    outline: none!important
    box-shadow: 0 0 0 0!important
  .fc-event .fc-event-resizer
    display: none!important
  .fc-timegrid-body, .fc-timegrid-body table
    width: 100%!important
  .fc-theme-standard td, .fc-theme-standard th,
  .calendar .fc-theme-standard td, .calendar .fc-theme-standard th
    border: 1px solid #EAEAEA
  .fc-scrollgrid thead th
    border: none
  .fc .fc-timegrid-col.fc-day-today
    background: #F7FAFE
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor,
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-shrink
    border: none!important
  .fc-scrollgrid-shrink
    border-bottom: none!important
  .fc-scrollgrid-shrink-cushion
    position: relative
    top: 45px
    font: 1.2rem $m!important
    color: $blue
    padding: 0 3px 0 3px
    text-align: center
  .fc-timegrid-event-harness>.fc-timegrid-event
    min-width: 200px!important
  &--week .fc
    .fc-timegrid-slot
      height: 2.5rem!important
    .fc-timegrid-slot-lane
      height: 2.5rem!important
    .fc-scrollgrid-shrink-cushion
      position: relative
      top: 25px
    .fc-timegrid-event-harness>.fc-timegrid-event
      min-width: 100%!important
    .calendar__item-week
      padding: 6px
      cursor: pointer
      display: flex
      flex-direction: column
      height: 100%
      font: 1.1rem $m
