@import '~assets/styles/variables.sass'

@import '~assets/styles/variables.sass'
#root
  .Mui-error
    input::placeholder
      color: $red!important
    fieldset
      border-color: $red!important
  .MuiInputBase-formControl
    legend
      font-size: 11px
  .MuiInputBase-root
    height: 46px
  .MuiTextField-root
    display: block
    width: 100%
  .MuiInputBase-root
    background: none
    width: 100%
  .MuiInputBase-input
    font: 1.4rem $r
    color: $black
  .MuiFormLabel-root
    color: rgba(0,0,0,.5)
    font: 1.4rem $r
  .MuiInputBase-sizeSmall
    height: 38px
    font-size: 1.6rem
  .MuiInputLabel-sizeSmall
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
      transform: translate(14px, 13px) scale(1)
      @media (max-width: $sm)
        transform: translate(12px, 17px) scale(1)
  .MuiTextField-root
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
      transform: translate(14px, 20px) scale(1)
  .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root
    transform: translate(14px, -7px) scale(0.75)
  .MuiOutlinedInput-notchedOutline
    border-color: rgba(0,0,0, .5)
    border-radius: 4px
  .Mui-focused
    .MuiOutlinedInput-notchedOutline
      border-color: rgba(0,0,0, .5)
      border-width: 1px
  .Mui-disabled
    background: #d9dada!important
    border-color: #d9dada!important

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
    @media (max-width: $sm)
      padding-top: 14px!important
      padding-bottom: 14px!important
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline
    border-color: #ff0000
  .MuiFormControl-root
    .Mui-error
      color: #ff0000!important
  .css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root
    transform: translate(12px, 25px) scale(1)
  .MuiTableCell-head
    font: 1.5rem $m
    color: $black
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select
    width: auto!important
  .MuiTableCell-body
    font: 1.4rem $r
    color: $black
  .MuiTablePagination-displayedRows
    font: 1.5rem $r
    color: $black
  .MuiTablePagination-selectLabel
    font: 1.4rem $r
    color: $black
  .css-194a1fa-MuiSelect-select-MuiInputBase-input
    display: flex
    align-items: center
  .MuiPaper-rounded
    overflow-x: auto
    padding-bottom: 20px
  .MuiFormControlLabel-label
    font: 1.4rem $r
  .select-small .MuiFormLabel-colorPrimary[data-shrink="false"]
    transform: translate(14px, 13px) scale(1)
  .MuiTablePagination-root
    .MuiInputBase-root
      width: auto!important
  // Кнопки
  .MuiIconButton-colorInherit
    svg
      path
        fill: $blue
  .MuiIconButton-colorError
    background: $red
    border-radius: 5px!important
    svg
      path
        fill: $white
  .MuiIconButton-colorPrimary
    background: $light-blue
    border-radius: 5px!important
    svg
      path
        fill: $white
  .MuiButton-root
    width: 100%
  .MuiButton-containedPrimary
    background: $light-blue

  .MuiButton-sizeLarge
    font: 1.8rem $m
    color: $white
    height: 52px
    border-radius: 8px
    @media (max-width: $xxl)
      height: 48px
    @media (max-width: $sm)
      height: 44px
  .MuiButton-sizeMedium
    font: 1.6rem $m
    color: $white
    height: 46px
    border-radius: 8px
    @media (max-width: $xxl)
      height: 44px
    @media (max-width: $sm)
      height: 42px
  .MuiButton-sizeSmall
    font: 1.4rem $m
    color: $white
    height: 38px
    border-radius: 5px
    @media (max-width: $xxl)
      height: 34px
    @media (max-width: $sm)
      height: 32px
  .MuiButton-textSuccess
    background: green
  .MuiButton-textPrimary
    background: $blue
  .MuiButton-colorInherit
    background: gray
  .MuiButton-textSecondary
    background: $red
  .MuiButton-textError
    background: red
  .MuiCircularProgress-colorSuccess
    color: $green
    width: 30px!important
    height: 30px!important

.MuiInputLabel-sizeSmall[data-shrink="false"]
  transform: translate(14px, 12px) scale(1)
