@import '~assets/styles/variables.sass'

.auth-page
  &__form
    width: 100%
  &__field
    width: 100%
    display: block
    margin-bottom: 12px
  &__label
    margin-bottom: 10px
    font: 1.4rem $m
    color: #B1B5C3
    text-transform: uppercase
  &__input
    font: 1.8rem $r
    color: $black
    border: 2px solid #B1B5C3
    width: 100%
    padding: 12px
    border-radius: 8px
    @media (max-width: $xxl)
      padding: 10px 12px
  &__button
    margin-top: 15px!important
